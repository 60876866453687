import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

const BonusPromotion = memo(({ name }) => {
  const { t } = useTranslation();

  return (
    <div className="bonus-promotion">
      <div className="bonus-promotion__row">
        <div className="bonus-promotion__name">
          {t('common.bonus.before.title')}
        </div>
        <div
          className={cn(
            'bonus-promotion__value',
            'bonus-promotion__value--crossed'
          )}
        >
          {t(`common.bonus.before.${name}`)}
        </div>
      </div>
      <div className="bonus-promotion__row">
        <div className="bonus-promotion__name">
          {t('common.bonus.now.title')}
        </div>
        <div
          className={cn(
            'bonus-promotion__value',
            'bonus-promotion__value--label'
          )}
        >
          <Trans i18nKey={`common.bonus.now.${name}`}>
            <span
              className={cn(
                'bonus-promotion__icon',
                'bonus-promotion__icon--bump'
              )}
            />
            <span
              className={cn(
                'bonus-promotion__icon',
                'bonus-promotion__icon--vip'
              )}
            />
          </Trans>
        </div>
      </div>
      <div
        className={cn(
          'bonus-promotion__label',
          `bonus-promotion__label--${name}`
        )}
      >
        {t('common.bonus.bonus')}
      </div>
    </div>
  );
});

BonusPromotion.displayName = 'BonusLabel';

BonusPromotion.propTypes = {
  name: PropTypes.string
};

export default BonusPromotion;
