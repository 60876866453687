class BackStorage {
  constructor() {
    this.backHistoryKey = '_backHistoryStateInitialPath';
    this.backHistoryState = '_backHistoryState';
  }

  setBackHistoryStorageInitialPath(path) {
    if (window) {
      window.localStorage.setItem(this.backHistoryKey, path);
    }
  }

  getBackHistoryStorageInitialPath() {
    if (window) {
      return window.localStorage.getItem(this.backHistoryKey);
    }
  }

  setBackHistoryStorage() {
    if (window) {
      window.localStorage.setItem(this.backHistoryState, '0');
    }
  }

  getBackHistoryStorage() {
    if (window) {
      return window.localStorage.getItem(this.backHistoryState);
    }
  }

  removeBackHistoryStorage() {
    if (window) {
      window.localStorage.removeItem(this.backHistoryState);
      window.localStorage.removeItem(this.backHistoryKey);
    }
  }
}

// eslint-disable-next-line import/prefer-default-export
export const BackHistoryStorage = new BackStorage();
