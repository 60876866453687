import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { connect, useDispatch } from 'react-redux';
import classNames from 'classnames';
import * as actionCreators from '../../actions';
import { togglePromotionModalView } from '../../actions';
import { sendAccessToken } from '../../queries/mutations';
import { getLangDefinition } from '../../utils/helpers';
import HeaderPlaceholder from '../Placeholders/HeaderPlaceholder';
import AdblockerDetector from '../Banner/BlockerDetector';
import FooterPlaceholder from '../Placeholders/FooterPlaceholder';
import Loader from '../Loader';
import AuthModal from '../AuthModal';
import SideMenuModal from '../SideMenuModal';
import { useBridgeState } from '../../bridge';
import PromotionModalView from '../PromotionModalView';
import useBackHistoryStorage from '../../hooks/useBackHistoryStorage';

const Banner = dynamic(() => import('../Banner'), {
  ssr: false
});

const WhiteHeader = dynamic(() => import('../WhiteHeader'), {
  loading: HeaderPlaceholder,
  ssr: false
});

const TabBarNavigation = dynamic(() => import('../TabBarNavigation'), {
  loading: HeaderPlaceholder,
  ssr: false
});

const Footer = dynamic(() => import('../Footer'), {
  loading: FooterPlaceholder,
  ssr: false
});

const mapStateToProps = state => {
  return {
    isVisible: state.drawerUI.isVisible,
    isSideMenuVisible: state.sideMenuModal?.isVisible,
    isPromotionModalView: state.promotionModalView?.isVisible,
    isAuthVisible: state?.authModal?.isVisible
  };
};

const Layout = ({
  isBanner,
  children,
  setBlockerDetector,
  toggleSideMenuModal,
  isAuthVisible,
  isSideMenuVisible,
  isPromotionModalView,
  toggleAuthModal,
  headerTitle,
  isBottomNavigation = true,
  isShowHeader = true,
  isFixedHeader = false,
  isShowBackButton = false,
  isShowCloseButton = true
}) => {
  const {
    i18n: { language }
  } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const { isWebView } = useBridgeState();
  const allClasses = classNames(
    'main-content',
    `locale-${getLangDefinition(language)}`
  );

  const closeAuthModalHandler = () => {
    toggleAuthModal(false);

    router.push(`${router.asPath.split('#')[0]}`, undefined, {
      shallow: true
    });
  };
  const accessToken = router?.query.access_token;
  const [mutateToken, { loading }] = useMutation(sendAccessToken);
  useBackHistoryStorage();

  useMemo(() => {
    if (accessToken) {
      mutateToken({
        variables: { accessToken }
      });
    }
  }, []);

  useEffect(() => {
    if (router?.asPath.match('#authentication')) {
      toggleAuthModal(true);
    } else if (router?.asPath.match('#more')) {
      toggleSideMenuModal(true);
    } else if (router?.asPath.match('#service_feature')) {
      dispatch(togglePromotionModalView(true));
    } else if (router?.asPath.match('#service_bump')) {
      dispatch(togglePromotionModalView(true));
    } else if (router?.asPath.match('#service_vip')) {
      dispatch(togglePromotionModalView(true));
    }

    router?.events.on('hashChangeComplete', url => {
      if (url.match('#authentication')) {
        toggleAuthModal(true);
      } else {
        toggleAuthModal(false);
      }

      if (url.match('#more')) {
        toggleSideMenuModal(true);
      } else {
        toggleSideMenuModal(false);
      }

      if (
        url.match('#service_bump') ||
        url.match('#service_feature') ||
        url.match('#service_vip') ||
        url.match('#restore') ||
        url.match('#personal_ads') ||
        url.match('#limit_payment')
      ) {
        dispatch(togglePromotionModalView(true));
      } else {
        dispatch(togglePromotionModalView(false));
      }
    });
  }, []);

  if (loading) return null;

  return (
    <div className={allClasses}>
      {isBanner && (
        <Banner
          key="lotriver-top-banner"
          containerClassName={'lotriver-top-banner'}
          containerId={'js-lotriver-top-banner'}
          sid={'220686'}
          bn={'2'}
          arCn={1}
        />
      )}
      {isShowHeader && (
        <WhiteHeader
          {...{
            headerTitle,
            isFixedHeader,
            isShowBackButton,
            isShowCloseButton
          }}
        />
      )}
      <AdblockerDetector setBlockerDetector={setBlockerDetector} />
      {children}
      <Loader pageLoader={true} />
      {isBottomNavigation && <TabBarNavigation />}
      {!(isWebView || isSideMenuVisible) && (
        <Footer
          isTabBarExist={isBottomNavigation}
          isBackgroundWhite={!isShowHeader}
        />
      )}
      {isSideMenuVisible && <SideMenuModal />}
      {isPromotionModalView && <PromotionModalView />}
      {isAuthVisible && (
        <AuthModal
          isOpen={isAuthVisible}
          modalId="auth"
          closeHandler={closeAuthModalHandler}
        />
      )}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  toggleDrawer: PropTypes.func,
  toggleAuthModal: PropTypes.func,
  isVisible: PropTypes.bool,
  isBanner: PropTypes.bool,
  isAuthVisible: PropTypes.bool,
  isSideMenuVisible: PropTypes.bool,
  isPromotionModalView: PropTypes.bool,
  setBlockerDetector: PropTypes.func,
  toggleSideMenuModal: PropTypes.func,
  isBottomNavigation: PropTypes.bool,
  isShowHeader: PropTypes.bool,
  isFixedHeader: PropTypes.bool,
  headerTitle: PropTypes.string,
  isShowBackButton: PropTypes.bool,
  isShowCloseButton: PropTypes.bool
};

export default connect(mapStateToProps, actionCreators)(Layout);
