import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import BonusPromotion from '../BonusPromotion';

const PromotionInfo = ({ message, additionalClasses, name }) => {
  return (
    <div className={cn('promotion__info-container', additionalClasses)}>
      <div className="promotion__info">{message}</div>
      {['vip', 'feature'].includes(name) && <BonusPromotion {...{ name }} />}
    </div>
  );
};

PromotionInfo.propTypes = {
  message: PropTypes.string,
  additionalClasses: PropTypes.string,
  name: PropTypes.string
};

export default PromotionInfo;
